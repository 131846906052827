<template>
  <div class="property-editor">
    {{$t('Заливка')}}
    <graphic-editor-select @click.native="showColorPickerWindow = !showColorPickerWindow" :active="showColorPickerWindow" :color="layer.color" :items="[layer.color]"></graphic-editor-select>
    <floating-window v-model="showColorPickerWindow" :title="$t('Заливка')" :bounds="bounds" :start-position="windowPosition">
      <color-picker v-model="layer.color"></color-picker>
    </floating-window>
  </div>
</template>

<script>
import GraphicEditorSelect from "@/components/redesign/graphicEditorComponents/graphicEditorSelect.vue";
import {Layer} from "@/store/models/layer";
import FloatingWindow from "@/components/redesign/floatingWindow.vue";
import {tryGetClientRects} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";
import ColorPicker from "@/components/redesign/colorPickerComponent/colorPicker.vue";
import {Position} from "@/store/models/position";

export default {
  name: "fillPropertyEditor",
  components: {ColorPicker, FloatingWindow, GraphicEditorSelect},
  data() {
    return {
      showColorPickerWindow: false,
      bounds: {},
      windowPosition: new Position(0,0)
    }
  },
  props: {
    layer: {
      type: Layer,
      required: true
    }
  },
  computed: {
    windowWidth() {
      return 304;
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      this.bounds = await tryGetClientRects(document.getElementById('graphicEditor'), 100, 10)
      const panelBounds = await tryGetClientRects(document.getElementById('rightSidePanel'), 100, 10)
      this.windowPosition = new Position(this.bounds.width - panelBounds.width - this.windowWidth - 10,10)
    })
  }
}
</script>

<style scoped lang="scss">
.property-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>