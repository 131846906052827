<template>
  <div
    :class="{
      'only-mobile': mobileView,
      'only-desktop': mobileView === false,
      'default-view': mobileView === null,
    }"
    class="action-sheet-wrapper"
  >
    <div class="action-sheet" @click.self="closeComponent">
      <div class="action-sheet__container">
        <div v-if="title || subtitle" class="action-sheet-header">
          <div class="right">
            <div v-if="title" class="action-sheet-header__title">
              {{ title }}
            </div>
            <div v-if="subtitle" class="action-sheet-header__subtitle">
              {{ subtitle }}
            </div>
          </div>
          <div class="left">
            <icon-component
              :size="24"
              clickability
              color="var(--colorIconSecondary)"
              icon="/default-icons/close.svg"
              @click-element="closeComponent"
            />
          </div>
        </div>
        <div v-on-clickaway="closeComponent" class="action-sheet-cells">
          <div
            v-for="cell in cells"
            :key="cell.function"
            class="action-sheet-cell"
            @click="selectCell(cell.function)"
          >
            <icon-component
              v-if="cell.icon"
              :icon="cell.icon"
              :size="24"
              color="var(--colorIconAccent)"
            />
            <div class="cell-value">{{ cell.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import {directive as onClickaway} from "vue-clickaway";

export default {
  name: "actionSheetComponent",
  components: {IconComponent},
  directives: {
    onClickaway,
  },
  props: {
    cells: {
      type: Array,
      default() {
        return [
          {
            icon: "/default-icons/add_circle.svg",
            text: "Title",
            function: "delete",
          },
          {
            icon: "/default-icons/add_circle.svg",
            text: "Title 2",
            function: "addCard",
          },
          {icon: "/default-icons/add_circle.svg", text: "", function: "1"},
          {icon: "", text: "Title 3", function: "2"},
          {icon: "", text: "Title 4", function: "3"},
          {icon: "", text: "Title 5", function: "4"},
        ];
      },
    },
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    mobileView: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    closeComponent() {
      this.$emit("close-component")
    },
    selectCell(func) {
      this.$emit(func);
      this.closeComponent();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin mobileView() {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: var(--colorOverlay);

  animation-name: show-back;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  .action-sheet {
    padding: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    overflow: auto;

    animation-name: show-modal;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    .action-sheet__container {
      width: 100%;
      padding: 8px 0;

      display: flex;
      flex-direction: column;
      margin: auto auto 0;
      overflow: auto;
      max-height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .action-sheet-header {
        display: flex;

        .left {
          display: flex;
          flex-direction: column;
        }

        .right {
        }
      }

      .action-sheet-cells {
        gap: 0;

        .action-sheet-cell {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 0 16px;

          .cell-value {
            padding: 14px 0;
          }
        }
      }
    }
  }
}

@keyframes show-modal {
  0% {
    transform: translate3d(0px, 100%, 0px);
  }
  100% {
    transform: translate3d(0px, 0, 0px);
  }
}

@keyframes show-back {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.action-sheet-wrapper {
  .action-sheet {
    .action-sheet__container {
      width: 256px;
      padding: 8px;
      border-radius: 12px;
      border: 0.5px solid var(--colorStrokeSecondary);
      background: var(--colorBackgroundContent);

      /* Light/Elevation M */
      box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05),
      0px 0px 8px 0px rgba(30, 30, 36, 0.1);

      .action-sheet-header {
        display: none;
        padding: 14px 16px;
        justify-content: space-between;
        gap: 4px;

        color: var(--colorTextSecondary);
        font-style: normal;

        .action-sheet-header__title {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.07px;
        }

        .action-sheet-header__subtitle {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
          letter-spacing: 0.012px;
        }
      }

      .action-sheet-cells {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .action-sheet-cell {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 0 16px;
          cursor: pointer;

          .cell-value {
            color: var(--colorTextPrimary);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            letter-spacing: 0.024px;

            padding: 8px 0;
          }
        }
      }
    }
  }

  &.only-mobile {
    @include mobileView;
  }
}

@media screen and (max-width: 743px) {
  .action-sheet-wrapper {
    &.default-view {
      @include mobileView;
    }
  }
}
</style>
