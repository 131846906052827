<template>
<div class="default-popup" ref="defaultPopup">
  <div v-if="!!$slots.button" @click="expandOrModal">
    <slot name="button"></slot>
  </div>
  <button v-if="!$slots.button" class="default-popup-expand" :class="{'active': expand}" @click="expandOrModal">
    <slot name="buttonContent"></slot>
  </button>
  <transition name="fade">
    <div v-if="expand" class="default-popup-content" :style="{top, left, width: width + 'px'}" ref=defaultPopupContent>
      <slot name="content" :closeMethod="expandOrModal"></slot>
    </div>
  </transition>
  <vue-bottom-sheet-vue2 v-if="needModal" class="nav-bottom-sheet-popup" ref="defaultPopupModal"
                         overlay-color="#17171C66" :max-width="10000" :z-index="5">
    <slot name="content"></slot>
  </vue-bottom-sheet-vue2>
</div>
</template>

<script>
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
const BOTTOM_NEAR_OFFSET = 50
const MARGIN = 10

export default {
  name: "defaultPopup",
  components: {
    VueBottomSheetVue2
  },
  data() {
    return {
      expand: false,
      top: '0px',
      left: '0px'
    }
  },
  props: {
    needModal: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number|String,
      default: 520
    }
  },
  methods: {
    expandOrModal() {
      if (window.innerWidth < 550) {
        if (this.needModal) {
          this.$refs.defaultPopupModal.open()
        } else {
          this.$emit('btn-no-modal-click')
          return
        }
      } else {
        this.expand = !this.expand
      }
    },
    handleClickOutside(event) {
      if (!this.$refs.defaultPopup || !this.$refs.defaultPopup.contains(event.target)) {
        this.expand = false
      }
    },
    findPosition() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const popupRect = this.$refs.defaultPopup.getBoundingClientRect()
      const popupContentRects = this.$refs.defaultPopupContent.getBoundingClientRect()
      const x = popupRect.left + scrollLeft;
      const y = popupRect.top + scrollTop;

      this.top = (y + popupRect.height + MARGIN + popupContentRects.height) > window.innerHeight
                  ? (y - MARGIN - popupContentRects.height) + 'px'
                  : (y + popupRect.height + MARGIN) + 'px'

      this.left = (x + popupRect.width + MARGIN + popupContentRects.width) > window.innerWidth
                  ? (x - MARGIN * 3 - popupContentRects.width) + 'px'
                  : (x + popupRect.width + MARGIN) + 'px'
    }
  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  watch: {
    expand(val) {
      if (val) {
        this.$nextTick(() => {
          this.findPosition()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.default-popup {
  &-expand {
    background-color: transparent;
    outline: none !important;
    border: none;
    padding: 12px;
    border-radius: 6px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--colorBackgroundTertiaryActive);
    }

    &.active {
      background-color: var(--colorBackgroundTertiaryActive);
    }
  }

  &-content {
    position: absolute;
    border-radius: 12px;
    background: var(--colorBackgroundContent);
    box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05), 0px 0px 8px 0px rgba(30, 30, 36, 0.10);
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
  }

  .nav-bottom-sheet-popup {
    z-index: 99999!important;

    .bottom-sheet__content {
      background-color: var(--colorBackgroundContent);
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 69px;
      z-index: 99999!important;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 455px) {
  .default-popup-expand {
    padding: 3px;
    font-size: 8px;
  }

  .default-popup-content {
    width: calc(100% - 20px);
    left: 10px !important;
    // right: 10px;
  }
}
</style>
