<template>
  <div class="spectrum-slider" :style="{
    width: (sliderWidth.toString() + 'px'),
    height: (sliderHeight.toString() + 'px'),
    left: (sliderPosition.x.toString() + 'px'),
    top: (sliderPosition.y.toString() + 'px')
  }"></div>
</template>

<script>
import {SliderPosition, tryGetClientRects} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";

export default {
  name: "spectrumSlider",
  data() {
    return {
      sliderWidth: 0,
      sliderHeight: 0,
      sliderPosition: new SliderPosition(0, 0),
      isDragging: false,
      parentOldStylePosition: null
    };
  },
  props: {
    parentRef: {
      type: String,
      required: true
    },
    axes: {
      type: Array,
      required: true
    },
    position: {
      type: SliderPosition | null,
      default() {
        return null;
      }
    }
  },
  computed: {
    parent() {
      return this.$parent.$refs[this.parentRef];
    }
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.updateSliderPosition(event);
      window.addEventListener('mousemove', this.updateSliderPosition);
      window.addEventListener('mouseup', this.stopDrag);
    },
    async updateSliderPosition(event) {
      if (!this.isDragging) return;

      await this.setSliderPosition(event.clientX, event.clientY);
      this.$emit('position-changed', this.sliderPosition)
    },
    stopDrag() {
      this.isDragging = false;
      window.removeEventListener('mousemove', this.updateSliderPosition);
      window.removeEventListener('mouseup', this.stopDrag);
    },
    calcCoordinate(coordinate, axisCoordinate, side) {
      let newCoordinate = coordinate - axisCoordinate - (this.sliderWidth / 2);

      if (newCoordinate < 0) {
        newCoordinate = 0;
      }

      if (newCoordinate > side - this.sliderWidth) {
        newCoordinate = side - this.sliderWidth;
      }

      return newCoordinate;
    },
    async setSliderPosition(x, y, init = false) {
      const rect = await tryGetClientRects(this.parent, 10, 100);
      const sliderPosition = new SliderPosition(0, 0)

      if (this.axes.includes('x')) {
        sliderPosition.x = this.calcCoordinate(x, init ? 0 : rect.left, rect.width)
      }

      if (this.axes.includes('y')) {
        sliderPosition.y = this.calcCoordinate(y, init ? 0 : rect.top, rect.height)
      }

      this.sliderPosition = sliderPosition;
    },
    initStartCoordinate() {
      if (!this.position) {
        return;
      }

      this.setSliderPosition(this.position.x, this.position.y, true);
    }
  },
  async mounted() {
    const rect = await tryGetClientRects(this.parent, 10, 50);
    this.sliderWidth = rect.width > 16 ? 16 : rect.width;
    this.sliderHeight = rect.height > 16 ? 16 : rect.height;

    this.parent.addEventListener('mousedown', this.startDrag);
    this.parent.addEventListener('mouseup', this.stopDrag);

    this.parentOldStylePosition = this.parent.style.position;
    this.parent.style.position = 'relative'; //насильно добавляем к родителю relative, так как slider absolute
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateSliderPosition);
    window.removeEventListener('mouseup', this.stopDrag);

    this.parent.removeEventListener('mousedown', this.startDrag);
    this.parent.removeEventListener('mouseup', this.stopDrag);

    this.parent.style.position = this.parentOldStylePosition;
  },
  watch: {
    position() {
      this.initStartCoordinate();
    }
  }
}
</script>

<style scoped lang="scss">
.spectrum-slider {
  position: absolute;
  border: 3px solid #FFF;
  border-radius: 100%;
  top: 0;
  box-shadow: 0px 4px 8px 0px rgba(23, 23, 28, 0.05), 0px 0px 4px 0px rgba(23, 23, 28, 0.10);
}
</style>