export default {
    methods:{
        setFillColors(){
            const rootStyles = getComputedStyle(document.documentElement);
            const accentVariables=[rootStyles.getPropertyValue('--colorAccentRed').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentOrange').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentGold').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentYellow').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentLime').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentGreen').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentTeal').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentBlue').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentRoyal').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentIndigo').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentPurple').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentPink').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentGray').toUpperCase().trim()]
            this.$store.commit('SET_ACCENT_FILL_VARIABLES',accentVariables)

            const customVariables=[rootStyles.getPropertyValue('--colorIconAccentInvariably').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorIconAccentExtraInvariably').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorIconPrimaryInvariably').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorIconContrastInvariably').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentGray').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentRed').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentOrange').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentYellow').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentGreen').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentBlue').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentRoyal').toUpperCase().trim(),
                rootStyles.getPropertyValue('--colorAccentPurple').toUpperCase().trim(),
            ]
            this.$store.commit('SET_CUSTOM_FILL_VARIABLES',customVariables)
        }
    }
}