<template>
  <div class="spectrum-range" ref="spectrumRange">
    <spectrum-slider parentRef="spectrumRange" :axes="['x']" @position-changed="setColor" :position="sliderPosition"></spectrum-slider>
    <canvas class="spectrum-layer" ref="spectrumLayer"></canvas>
  </div>
</template>

<script>
import {
  ColorRGBA, defaultColor,
  findColorPosition, SliderPosition,
  tryGetClientRects
} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";
import SpectrumSlider from "@/components/redesign/colorPickerComponent/spectrumSlider.vue";

//каждый охотник желает знать где сидит фазан пурпурный
const SPECTRUM_COLORS = [
  "#FF4F4F",
  "#FF9D57",
  "#FFB956",
  "#FBD565",
  "#81D581",
  "#58C095",
  "#53BCC6",
  "#68ABEE",
  '#6F77FE',
  "#4055BF",
  "#6653C6",
  "#AB78DD",
  "#D38AE5",
  "#E893E8",
];

export default {
  name: "spectrumRange",
  components: {SpectrumSlider},
  data() {
    return {
      lastColor: new ColorRGBA(0,0,0,1),
      sliderPosition: null
    }
  },
  props: {
    color: {
      type: ColorRGBA,
      default() {
        return defaultColor()
      }
    }
  },
  methods: {
    async drawSpectrum() {
      const rect = await tryGetClientRects(this.$refs.spectrumRange, 10, 100);
      const canvas = this.$refs.spectrumLayer;

      canvas.width = rect.width;
      canvas.height = rect.height;

      const context = canvas.getContext("2d");
      const gradient = context.createLinearGradient(0, 0, canvas.width, 0);

      for (let i = 0; i < SPECTRUM_COLORS.length; i++) {
        const gradientRatio = Math.round((i / SPECTRUM_COLORS.length) * 100) / 100;
        gradient.addColorStop(gradientRatio, SPECTRUM_COLORS[i]);
      }

      context.fillStyle = gradient;
      context.fillRect(0, 0, canvas.width, canvas.height);
    },
    setColor(sliderPosition) {
      const context = this.$refs.spectrumLayer.getContext("2d");
      const imageData = context.getImageData(sliderPosition.x, 1, 1, 1);
      const color = new ColorRGBA(imageData.data[0], imageData.data[1], imageData.data[2], this.color.a);
      this.lastColor = color;

      this.$emit('color-changed', color)
    },
    async presetColor() {
      if (this.lastColor.r === this.color.r &&
          this.lastColor.g === this.color.g &&
          this.lastColor.b === this.color.b) {
        return;
      }

      const rect = await tryGetClientRects(this.$refs.spectrumRange, 10, 100);
      const context = this.$refs.spectrumLayer.getContext("2d");
      const {data} = context.getImageData(0, 0, rect.width, 1);
      const position = findColorPosition(data, rect.width, 1, this.color, 1)

      if (!position || (this.sliderPosition && (this.sliderPosition.x === position.x))) {
        return;
      }

      this.sliderPosition = new SliderPosition(position.x, position.y)
    }
  },
  async mounted() {
    await this.drawSpectrum();
    await this.presetColor();
  },
  watch: {
    color() {
      this.presetColor();
    }
  }
}
</script>

<style scoped lang="scss">
.spectrum-range {
  position: relative;
  height: 16px;
  border-radius: 1000px;
  overflow: hidden;
}
</style>