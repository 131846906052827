<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import DefaultPopup from "@/components/redesign/defaultPopup.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";

export default {
  name: "OptionsRow",
  components: {DefaultButton, DefaultPopup, IconComponent},
  props: {
    activeTab: {
      type: String|Number,
      default: 'archive'
    }
  }
}
</script>

<template>
  <default-popup
      width="fit-content"
      class="custom-style-popup"
  >
    <template v-slot:buttonContent>
      <icon-component
          :icon="'/default-icons/more_horiz.svg'"
          :color="'var(--colorIconAccent)'"
          :size="20"
      />
    </template>
    <template v-slot:content>
      <default-button
          @click-element="$emit('read-more')"
          type="tertiary"
          size="medium"
          leftIcon="/default-icons/info_fill.svg"
      >{{$t('Подробнее')}}</default-button>
<!--      <default-button
          @click-element="visibleModalBanks = true"
          type="tertiary"
          size="medium"
          leftIcon="/default-icons/bank.svg"
      >{{$t('Дублировать')}}</default-button>-->
      <default-button
          @click-element="$emit('archive')"
          type="tertiary"
          size="medium"
          leftIcon="/default-icons/history.svg"
      >{{activeTab === 'archive' ? $t('Восстановить') : $t('Архивировать')}}</default-button>
    </template>
  </default-popup>
</template>

<style scoped lang="scss">
.custom-style-popup {
  &:deep(.default-popup-expand) {
    padding: 8px;
  }
}
</style>