<script>
export default {
  name: "tableRowChips",
  props: {
    chips: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
<div class="table-row-chips" v-if="chips">
  <div v-for="chip of chips">{{ chip ?? 'null' }}</div>
</div>
</template>

<style scoped lang="scss">
.table-row-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  > * {
    border: 0.5px solid var(--colorStrokePrimary);
    color: var(--colorTextPrimary);
    text-align: center;
    padding: 7px 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03px;
    border-radius: 6px;
  }
}
</style>