<template>
  <div class="alpha-range" ref="alphaRange">
    <div class="color-layer" :style="{background: this.linearGradient}"></div>
    <spectrum-slider parent-ref="alphaRange" :axes="['x']" @position-changed="setAlpha" :position="sliderPosition"></spectrum-slider>
  </div>
</template>

<script>
import SpectrumSlider from "@/components/redesign/colorPickerComponent/spectrumSlider.vue";
import {
  ColorRGBA, defaultColor,
  rgbaToHex,
  SliderPosition,
  tryGetClientRects
} from "@/components/redesign/colorPickerComponent/colorPickerHelpers";

export default {
  name: "alphaRange",
  components: {SpectrumSlider},
  data() {
    return {
      sliderPosition: null,
      lastAlpha: 0
    }
  },
  props: {
    color: {
      type: ColorRGBA,
      default() {
        return defaultColor()
      }
    }
  },
  computed: {
    linearGradient() {
      return "linear-gradient(90deg, transparent, " + rgbaToHex(new ColorRGBA(this.color.r, this.color.g, this.color.b, 1)) + ")";
    }
  },
  methods: {
    setAlpha(sliderPosition) {
      const min = 0;
      const max = this.$refs.alphaRange.getBoundingClientRect().width;
      const x = sliderPosition.x;
      const a = (x - min) / (max - min);
      this.lastAlpha = a

      this.$emit('alpha-changed', new ColorRGBA(this.color.r, this.color.g, this.color.b, a));
    },
    async setSliderPosition() {
      const rect = await tryGetClientRects(this.$refs.alphaRange, 50, 5)
      const min = 0;
      const max = rect.width;
      const x = this.color.a * (max - min) + min;

      this.sliderPosition = new SliderPosition(x, 0)
    }
  },
  async mounted() {
    await this.setSliderPosition()
  },
  watch: {
    async color() {
      if (this.lastAlpha === this.color.a) {
        return
      }

      await this.setSliderPosition()
    }
  }
}
</script>

<style scoped lang="scss">
.alpha-range {
  position: relative;
  height: 16px;
  border-radius: 1000px;
  overflow: hidden;
  background: url('/public/default-icons/transparent_back.png') repeat;

  .color-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background 0.2s ease;
  }
}
</style>