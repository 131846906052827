<template>
  <div class="card-body">
    <modal-container
        v-if="visibleModal"
        :title="$t('Отделка')"
        :confirmText="$t('сохранить')"
        :width="'700px'"
        @confirm-option="saveRepair"
        @close-modal="closeModalEditor"
    >
      <div class="editor-modal-blocks-project">
        <div class="row">
          <div class="form-group">
            <label for="balcony">{{ $t('Литер') }}</label>
            <div class="container-dropdown-stock" v-if="litersSelect.length">
              <dropdown-component :elements="litersSelect" :open="'bottom'" :multiple="true" v-model="changeData.developments" :value="changeData.developments"></dropdown-component>
            </div>
          </div>
          <div class="form-group">
            <label for="type">{{$t('Помещение')}}</label>
            <dropdown-component :elements="room_types" :none-hide="true" :open="'bottom'" :multiple="false"  v-model="changeData.type" :value="changeData.type"></dropdown-component>
          </div>

          <div class="form-group">
            <label for="banks">{{ $t('БанковскийФильтр') }}</label>
            <dropdown-component :elements="programsValue" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.programs" :value="changeData.programs"></dropdown-component>
          </div>
          <div class="form-group">
            <label for="bpv">{{ $t('бпв') }}</label>
            <dropdown-component :elements="[{id: 0, text:'Не применяется'}, {id: 1, text:'Применяется'}, {id: null, text:'Без разницы'}]" :none-hide="true" :open="'bottom'" :multiple="false" v-model="changeData.bpv" :value="changeData.bpv"></dropdown-component>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="floor_min">{{ $t('название') }}</label>
                <input type="text" class="form-control" id="floor_min" v-model="changeData.name">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="floor_max">{{ $t('СтоимостьЗаМ2') }}</label>
                <input type="number" class="form-control" id="floor_max" v-model="changeData.price">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="max_credit">{{$t('СуммаКредитаДО')}}</label>
            <input type="number" class="form-control" id="max_credit" v-model="changeData.max_credit">
          </div>
          <div class="form-group">
            <label for="room_count">{{ $t('КоличествоКомнат') }}</label>
            <slot v-if="rooms.length">
              <dropdown-component :elements="rooms" :none-hide="true" :open="'bottom'" :multiple="true" v-model="changeData.room_count" :value="changeData.room_count"></dropdown-component>
            </slot>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="square_min">{{$t('ПлощадьОт')}}</label>
                <input type="text" class="form-control" id="square_min" v-model="changeData.square_min">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="square_max">{{$t('ПлощадьДо')}}</label>
                <input type="number" class="form-control" id="square_max" v-model="changeData.square_max">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="img-admin-repair">
              <label for="fileBlock">{{ $t('Изображение') }}</label>
              <label for="fileBlock" class="btn btn-info btn-sm m-l-10">{{ $t('загрузитьИзображение') }}</label>
            </div>
            <input id="fileBlock" multiple  type="file" ref="fileBlock" v-on:change="handleRepairFileUploads()" class="form-control hidden">
            <div class="files">
              <div class="file"  v-for="(src, i) of changeData.picture">
                <div class="delete" @click="deleteRepairFile(i)">❌</div>
                <img :src="src" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal-container>
    <div class="card-body">
      <div class="container p-30" id="wrapper-row-admin">
        <div class="row">
          <div class="col-md-12 main-datatable">
            <button class="btn btn-sm btn-success d-inline-block mb-2 col-12" @click="createRepair()">{{ $t('Добавить') }}</button>
            <div class="card_body">
              <div class="overflow-x">
                <table id="filtertable2" class="table cust-datatable dataTable no-footer">
                  <thead>
                  <tr>
                    <th>{{ $t('Литер') }}</th>
                    <th>{{ $t('название') }}</th>
                    <th>{{ $t('Стоимость') }}</th>
                    <th>{{ $t('КолвоКомнат') }}</th>
                    <th>{{$t('Площадь')}}</th>
                    <th>{{ $t('Программы') }}</th>
                    <th>{{ $t('бпв') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="repair in repairs" :key="repair.id">
                    <td><slot v-if="repair.developments">{{getComplex(repair.developments)}}</slot></td>
                    <td>{{repair.name}}</td>
                    <td>{{repair.price}}</td>
                    <td><slot v-if="repair.room_count">{{getRoomCount(repair.room_count)}}</slot></td>
                    <td><slot v-if="repair.square_min">{{repair.square_min}}</slot><slot v-if="repair.square_max"> - {{repair.square_max}}</slot></td>
                    <td><slot v-if="Object.keys(banks).length && repair.programs">{{getPrograms(repair.programs)}}</slot></td>
                    <td><slot v-if="repair.bpv === 1">{{ $t('Применяется') }}</slot><slot v-if="repair.bpv === 0">{{ $t('НеПрименяется') }}</slot></td>
                    <td>
                      <div class="btn-admin-nowrap-column">
                        <button  @click="copyRepair(repair.id)" class="btn btn-sm btn-primary">{{$t('скопировать')}}</button>
                        <button  @click="showModal(repair)" class="btn btn-sm btn-warning">{{$t('редактировать')}}</button>
                        <button class="btn btn-sm btn-danger m-l-10" @click="deleteRepair(repair.id)">{{ $t('удалить') }}</button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'datatables.net-bs5'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5'
import 'datatables.net-select-bs5'
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'
import $ from 'jquery'
import {mapGetters, mapMutations} from "vuex";
import priceFormat from "@/mixins/priceFormat";
import axios from "axios";
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";

export default {
  name: "RepairsEditorProject",
  components: {ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data(){
    return{
      complex:{},
      table: null,
      changeData: {},
      visibleModal:false,
      room_types: [
        {id: null, text: 'Не выбрано'},
        {id: 'Квартира', text: 'Квартира'},
        {id: 'Апартаменты', text: 'Апартаменты'}
      ],
      programsValue: [],
      rooms:[],
      banks: {},
      repairs: [],
    }
  },
  methods:{
    loadRepairs() {
      axios.get('/api/get-calculator-programs').then(response => {
        var banks = response.data
        var temp = {}
        var temp2 = []
        banks.forEach((bank) => {
          temp[bank.id] = '['+bank.id+'] '+bank.name
          temp2.push({
            id: bank.id,
            text: '['+bank.id+'] '+bank.name
          })
        })
        this.banks = temp
        this.programsValue = temp2
      })
      if(this.table !== null) this.table.destroy()
      axios.get('/api/get-repairs?id='+this.$route.params.id).then(response => {
        this.repairs = response.data
        setTimeout(() => {
          $.fn.dataTable.ext.errMode = 'none';
          this.table = $('#filtertable2').DataTable({
            stateSave: true,
            destroy: true,
            paging: true,
            pageLength: 25,
            language: {
              'decimal': '',
              'emptyTable': `${this.$i18n.t('ДанныхНет')}`,
              'info': `${this.$i18n.t('ПоказаноС')} _START_ ${this.$i18n.t('по')} _END_ ${this.$i18n.t('из')} _TOTAL_ ${this.$i18n.t('строк')}`,
              'infoEmpty': '',
              'infoFiltered': `(${this.$i18n.t('найденоИзИзначальных')} _MAX_ строк)`,
              'infoPostFix': '',
              'thousands': ',',
              'lengthMenu': `${this.$i18n.t('ПоказатьПо')} _MENU_`,
              'loadingRecords': `${this.$i18n.t('Загрузка')}...`,
              'processing': `${this.$i18n.t('Поиск')}...`,
              'search': `${this.$i18n.t('Поиск')}:`,
              'zeroRecords': `${this.$i18n.t('СовпаденийНеНайдено')}`,
              'paginate': {
                'first': `${this.$i18n.t('Первая')}`,
                'last': `${this.$i18n.t('Последняя')}`,
                'next': `${this.$i18n.t('Вперед')}`,
                'previous': `${this.$i18n.t('Назад')}`
              },
              'aria': {
                'sortAscending': `: ${this.$i18n.t('сортировкаПоВозрастанию')}`,
                'sortDescending': `: ${this.$i18n.t('сортировкаПоУбыванию')}`
              }
            }
          })
        }, 100)
      })
    },
    copyRepair(id) {
      axios.post('/api/copy-repair?id='+id).then(()=>{
        this.loadRepairs()
      })
    },
    createRepair() {
      axios.post('/api/create-repair?id='+this.$route.params.id).then((res)=>{
        window.open('/repair/' + res.data.id, "_self")
      })
    },
    saveRepair() {
      const object = structuredClone(this.changeData)
      if(object.picture.length===0)object.picture=null
      axios.post('/api/post-repair?id='+this.changeData.id, object).then(()=>{
        this.closeModalEditor()
      })
    },
    deleteRepair(id) {
      axios.post('/api/delete-repair?id='+id).then(()=>{
        this.loadRepairs()
      })
    },
    deleteRepairFile (id) {
      this.changeData.picture.splice(id, 1)
    },
    handleRepairFileUploads () {
      var input = 'fileBlock'
      var files = this.$refs[input].files
      var self = this
      for (let i in files) {
        if (typeof files[i] === 'object') {
          var reader = new FileReader()
          reader.fileName = files[i].name
          reader.onload = function (e) {
            $.post('https://cdn.itrielt.ru/upload/file', {
              file: {
                name: e.target.fileName,
                data: e.target.result
              }
            }).then(data => {
              if (data.status === 'success') {
                self.changeData.picture.push(data.url)
                self.$forceUpdate()
              }
            })
          }
          reader.readAsDataURL(files[i])
        }

      }


    },

    getComplex (developments) {
      var array =[]
      developments.forEach(val => {
        if(val && this.liters[val]) {
          array.push(this.liters[val])
        }
      })
      return array.join(', ')
    },
    getRoomCount(roomCount) {
      let array= [];
      roomCount.forEach(val => {
        if (val && this.rooms[val]) {
          array.push(this.rooms[val])
        }
      })
      return array.join(', ');
    },
    getPrograms (banks) {
      var array =[]
      banks.forEach(val => {
        if(val && this.banks[val]) {
          array.push(this.banks[val])
        }
      })
      return array.join(', ')
    },

    closeModalEditor(){
      this.visibleModal=false
      this.changeData = {}
    },
    showModal (data) {
      if(data.id){
        this.changeData = data
        if(!this.changeData.picture)this.changeData.picture=[]
        this.visibleModal = true
      }
    },
  },
  computed:{
    ...mapGetters({
      storeComplex: 'editorProjectData/getComplex',
      liters: 'editorProjectData/getLiters',
      litersSelect: 'editorProjectData/getLitersSelect',
    }),
  },
  watch:{
    storeComplex(newValue){
      this.complex=JSON.parse(JSON.stringify(newValue))
    }
  },
  mounted() {
    this.complex=JSON.parse(JSON.stringify(this.storeComplex))
    this.rooms = Object.values(this.complex.rooms)
    this.loadRepairs()
  }
}
</script>

<style scoped lang="scss">

</style>