<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
export default {
  name: "FromMediaUploader",
  components: {IconComponent, Loader, DefaultButton},
  props: {
    value: {}
  },
  data() {
    return {
      file: null,
      isLoadingFile: false
    }
  },
  computed: {
    textBtn() {
      if(this.value) {
        return 'ИзменитьФайл'
      }
      return 'ВыбратьФайл'
    }
  },
  methods: {
    deletedFile() {
      this.$emit('input', null)
    },
    async loadFile() {
      this.isLoadingFile = true
      const form = new FormData()
      let file = this.$refs['file'].files[0]
      form.append('file', file)
      const data = await axios.post('https://cdn.itrielt.ru/upload/file', form)
      if (data.data.status === 'success') {
        this.$emit('input', data.data.url)
      }
      this.isLoadingFile = false
      this.$refs['file'].value = ''
    },
    openSelectFile() {
      this.$refs['file'].click()
    },
  }
}
</script>

<template>
<div class="from-media-uploader">
  <div class="media-file-uploader">
    <loader v-if="isLoadingFile"/>
    <div v-else-if="value" class="uploaded-img">
      <img :src="value" alt="">
      <div @click="deletedFile" class="close">
        <icon-component
            :icon="'/default-icons/close.svg'"
            :color="'var(--colorIconContrastInvariably)'"
            :size="24"
        />
      </div>
    </div>
    <input @change="loadFile" hidden type="file" name="file" ref="file">
    <default-button
        size="medium"
        type="secondary"
        @click-element="openSelectFile"
    >
      {{textBtn}}</default-button>
  </div>
</div>
</template>

<style scoped lang="scss">
.from-media-uploader {
  .media-file-uploader {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .uploaded-img {
      position: relative;
      .close {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
      }
      > img {
        width: 100%;
        border-radius: 8px;
      }
    }

  }
}
</style>