<template>
  <div class="object-settings-page">
    <template v-if="!load && !loadSettings">
      <modal-container
          v-if="visibleEditorTable"
          :title="$t('НастройкаТаблицы')"
          :confirm-text="$t('Применить')"
          width="560px"
          @confirm-option="saveEditingRows"
          @close-modal="closeEditorTable"
      >
        <div class="editor-rows-table">
          <draggable v-model="editingRows"  v-bind="dragOptions" handle=".editor-rows-section__drag">
            <transition-group type="transition" name="flip-list">
              <div class="editor-rows-section" v-for="(section,index) in editingRows" :key="section.key">
                <div class="editor-rows-section__drag">
                  <icon-component
                      :icon="'/default-icons/drag.svg'"
                      :color="'var(--colorIconSecondary)'"
                      :size="24"
                  />
                </div>
                <div class="editor-rows-section__name">{{$t(section.name)}}</div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </modal-container>
      <modal-container
          v-if="visibleSettingsGallery"
          :title="$t('НастройкаГалереи')"
          :confirm-text="$t('Применить')"
          width="560px"
          @confirm-option="saveSettingsGallery"
          @close-modal="closeSettingsGallery"
      >
        <div class="edit-settings-gallery">
          <draggable v-model="temporaryGalleryList"  v-bind="dragOptions" handle=".gallery-section__drag">
            <transition-group type="transition" name="flip-list">
              <div class="gallery-section" v-for="(section,index) in temporaryGalleryList" :key="section.key">
                <div class="gallery-section__drag">
                  <icon-component
                      :icon="'/default-icons/drag.svg'"
                      :color="'var(--colorIconSecondary)'"
                      :size="24"
                  />
                </div>
                <div class="gallery-section__form">
                  <form-item
                      :title="`${$t('ВкладкаГалереи')} ${index+1}`"
                      :required="true"
                      :removable="temporaryGalleryList.length>1"
                      @remove-item="deleteGalleryPoint(section)"
                  >
                    <default-new-input
                        v-model="section.value"
                        :notificationError="!section.value&&visibleErrorGallery"
                        :placeholder="$t('ВведитеНазвание')"
                    />
                  </form-item>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <template #headers-right>
          <div class="functions-settings-gallery">
            <default-button
                :left-icon="'/default-icons/add_circle.svg'"
                :width="'auto'"
                :size="'medium'"
                :type="'tertiary'"
                @click-element="addGalleryPoint"
            >{{ $t('Добавить') }}</default-button>
          </div>
        </template>
      </modal-container>
      <modal-container
          v-if="visibleSaveBlock"
          mode="split"
          :title="currentSectionCustom?$t('РедактированиеБлока'):$t('ДобавлениеБлока')"
          :confirm-text="$t('Применить')"
          width="600px"
          :touch-device-full-screen="true"
          @confirm-option="saveBlockConfirm"
          @close-modal="closeEditorBlock"
      >
        <div class="block-add-modal">
          <div class="default-section section-with-padding">
            <form-item
                :title="$t('НазваниеБлока')"
                :required="true"
                :notificationError="!editorBlock.name&&visibleErrorGeneral"
                :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
            >
              <default-new-input
                  v-model="editorBlock.name"
                  :notificationError="!editorBlock.name&&visibleErrorGeneral"
                  :placeholder="$t('ВведитеНазваниеБлока')"
              />
            </form-item>
            <form-item
                :title="$t('ТипБлока')"
            >
              <segmented-control
                  :tabs="addBlockTypes"
                  v-model="currentBlockType"
                  :stretched="true"
              ></segmented-control>
            </form-item>
          </div>
          <div class="banner-editor">
            <group-header
                :title="currentBlockType.value"
                :title-tooltip="currentBlockType.id==='gallery'?$t('ФайлыФормата'):''"
                :description-tooltip="currentBlockType.id==='gallery'?'.PNG / .JPG':''"
            >
              <template #control-element>
                <div class="editor-gallery-desktop" v-if="currentBlockType.id==='gallery'">
                  <default-button
                      :left-icon="'/default-icons/edit.svg'"
                      width="auto"
                      :type="'tertiary'"
                      :size="'medium'"
                      @click-element="editGallery"
                  >{{$t('Редактировать')}}</default-button>
                </div>
                <div class="editor-gallery-mobile" v-if="currentBlockType.id==='gallery'">
                  <default-button
                      :left-icon="'/default-icons/edit.svg'"
                      width="auto"
                      :type="'tertiary'"
                      :size="'medium'"
                      @click-element="editGallery"
                  />
                </div>
              </template>
            </group-header>
            <div class="banner-editor__form" v-if="currentBlockType.id==='banner'">
              <div class="info-banner">
                <div class="default-section">
                  <form-item
                      :title="$t('Заголовок')"
                      :required="true"
                      :notificationError="!optionsBanner.title&&visibleErrorGeneral"
                      :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
                  >
                    <default-new-input
                        v-model="optionsBanner.title"
                        :notificationError="!optionsBanner.title&&visibleErrorGeneral"
                        :placeholder="$t('ВведитеТекстЗаголовка')"
                    />
                  </form-item>
                  <form-item
                      :title="$t('ЦветЗаголовка')"
                  >
                    <fill-dropdown :type-fill="'color'" v-model="optionsBanner.title_color"/>
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="$t('Подзаголовок')"
                  >
                    <textarea-component v-model="optionsBanner.subtitle" :placeholder="$t('ВведитеТекстПодзаголовка')"/>
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="$t('ЦветПодзаголовка')"
                  >
                    <fill-dropdown :type-fill="'color'" v-model="optionsBanner.subtitle_color"/>
                  </form-item>
                  <form-item
                      :title="$t('ЦветТекста')"
                  >
                    <fill-dropdown :type-fill="'color'" v-model="optionsBanner.description_color"/>
                  </form-item>
                </div>
                <div class="default-section">
                  <form-item
                      :title="$t('Текст')"
                  >
                    <textarea-component v-model="optionsBanner.description"/>
                  </form-item>
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="actions-banner">
                <div class="default-section section-with-padding">
                  <form-item
                      :title="$t('ОсновнаяКнопка')"
                  >
                    <default-new-input
                        v-model="optionsBanner.left_button.text"
                        :placeholder="$t('Согласиться')"
                    />
                  </form-item>
                  <form-item
                      :title="$t('Ссылка')"
                  >
                    <default-new-input
                        v-model="optionsBanner.left_button.link"
                        :placeholder="'https://itrielt.ru/'"
                    />
                  </form-item>
                </div>
                <div class="default-section section-with-padding">
                  <form-item
                      :title="$t('ДополнительнаяКнопка')"
                  >
                    <default-new-input
                        v-model="optionsBanner.right_button.text"
                        :placeholder="$t('Подробнее')"
                    />
                  </form-item>
                  <form-item
                      :title="$t('Ссылка')"
                  >
                    <default-new-input
                        v-model="optionsBanner.right_button.link"
                        :placeholder="'https://itrielt.ru/'"
                    />
                  </form-item>
                </div>
                <div class="close-action">
                  <switch-component
                      :title="$t('ВозможностьСкрытьБаннер')"
                      :title-tooltip="`«${$t('Крестик')}»`"
                      :description-tooltip="$t('КнопкаВВерхнемПравомУглуПозволяетСкрытьБаннерЕслиОбновитьСтраницуБаннерСноваОтобразитсяНаЭкранеПользователя')"
                      :width-tooltip="292"
                      v-model="optionsBanner.close"
                  />
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="background-banner">
                <div class="default-section section-with-padding">
                  <div class="default-section__block">
                    <form-item
                        :title="$t('Фон')"
                    >
                      <fill-dropdown :top-position="true" v-model="optionsBanner.background"/>
                    </form-item>
                  </div>
                  <div class="default-section__block">
                    <form-item
                        :title="$t('ТипФона')"
                    >
                      <segmented-control
                          :tabs="backgroundBannerThemes"
                          v-model="currentBackgroundBannerTheme"
                          :stretched="true"
                      ></segmented-control>
                    </form-item>
                  </div>
                </div>
              </div>
              <div class="separator-editor-banner">
                <div class="separator-editor-banner__line"></div>
              </div>
              <div class="cover-banner">
                <div class="section-with-padding">
                  <form-item
                      :title="$t('Обложка')"
                      :title-tooltip="$t('Обложка')"
                      :description-tooltip="$t('ЭлементБаннераСлева')"
                  >
                    <div class="tabs-media-desktop">
                      <segmented-control
                          :tabs="contentBannerTypes"
                          v-model="currentBannerContentType"
                          :stretched="true"
                      ></segmented-control>
                    </div>
                    <div class="tabs-media-mobile">
                      <segmented-control
                          :tabs="contentBannerTypes"
                          v-model="currentBannerContentType"
                          :stretched="true"
                          :visible-text="false"
                      ></segmented-control>
                    </div>
                  </form-item>
                </div>
                <div class="default-section section-with-padding" v-if="currentBannerContentType.id==='icon'">
                  <div class="default-section__block">
                    <form-item
                        :title="$t('Иконка')"
                    >
                      <icon-picker
                          :top-position="true"
                          v-model="optionsBanner.icon"
                      />
                    </form-item>
                  </div>
                  <div class="default-section__block">
                    <form-item
                        :title="$t('ЦветИконки')"
                    >
                      <fill-dropdown :top-position="true" :type-fill="'color'" v-model="optionsBanner.icon_color"/>
                    </form-item>
                  </div>
                </div>
                <div v-else-if="currentBannerContentType.id==='image'">
                  <switch-component
                      :title="$t('ИзображениеСФонаБаннера')"
                      :disabled="disabledCopyImgBackground"
                      v-model="optionsBanner.mediaImgFromBackground"
                  />
                  <div class="section-with-padding" v-if="!optionsBanner.mediaImgFromBackground">
                    <form-item
                        :title="$t('Изображение')"
                    >
                      <from-media-uploader v-model="optionsBanner.mediaImgUpload"/>
                    </form-item>
                  </div>
                </div>
                <div class="section-with-padding" v-else-if="currentBannerContentType.id==='avatar'">
                  <div class="description-cover-banner">{{$t('КаждомуПользователюБудетОтображатьсяЕгоСобственноеИзображениеПрофиля')}}</div>
                </div>
              </div>
            </div>
            <div class="banner-editor__form" v-else-if="currentBlockType.id==='gallery'">
              <default-tabs
                  mode="primary"
                  size="medium"
                  :tabs="fixGalleryList"
                  :padding="true"
                  v-model="currentGallery"
              />
              <div class="media-settings">
                <media-uploader
                  v-model="currentGallery.media"
                />
              </div>
            </div>
            <div class="banner-editor__form" v-else-if="currentBlockType.id==='iFrame'">
              <div class="section-with-padding">
                <form-item
                    :title="$t('Ссылка')"
                    :caption="$t('ИтоговыйБлокНаСтраницеОбъектаБудетССоотношением11ДляМобильныхУстройствИ169ДляВсехОстальных')"
                    :required="true"
                    :notificationError="!iFrameLink&&visibleErrorGeneral"
                    :notificationErrorText="$i18n.t('ПолеЯвляетсяОбязательным')"
                >
                  <default-new-input
                      v-model="iFrameLink"
                      :notificationError="!iFrameLink&&visibleErrorGeneral"
                  />
                </form-item>
              </div>
            </div>
          </div>
        </div>
      </modal-container>
      <div class="object-settings-types">
        <default-tabs
            mode="primary"
            size="medium"
            :tabs="tabsTypesObject"
            :padding="true"
            v-model="currentTab"
        />
      </div>
      <div class="object-settings-main">
        <div class="header-functions-table">
          <div></div>
          <div class="header-functions-table__right">
            <default-button
                :left-icon="'/default-icons/add_circle.svg'"
                :width="'auto'"
                :size="'medium'"
                @click-element="startEditBlock"
            >{{$t('Добавить')}}</default-button>
            <div>
              <default-popup
                  width="fit-content"
              >
                <template v-slot:buttonContent>
                  <icon-component
                      :icon="'/default-icons/more_horiz.svg'"
                      :color="'var(--colorIconAccent)'"
                      :size="20"
                  />
                </template>
                <template v-slot:content>
                  <default-button
                      @click-element="openEditorTable"
                      type="tertiary"
                      size="medium"
                      leftIcon="/default-icons/settings.svg"
                  >{{$t('Настройки')}}</default-button>
                </template>
              </default-popup>
            </div>
          </div>
        </div>
        <table class="table table-responsive table-bordered table-hover table-settings">
          <thead>
          <tr>
            <th>#</th>
            <th>{{$t('Значение')}}</th>
            <th style="cursor: pointer" v-for="(name, role) in roles">
              <div class="settings-choice-wrapper" @click="invertRole(role)">
                <div class="settings-choice__checkbox">
                  <input
                      class="custom-checkbox"
                      :id="'selectedAccess'+role"
                      type="checkbox"
                      v-model="tempCheckRole[role]"
                      @input="invertRole(role)"
                  />
                  <label :for="'selectedAccess'+role"></label>
                </div>
                <div class="settings-choice__text">
                  <label class="settings-choice__text_label"
                         :for="'selectedAccess'+role">{{ name }}</label>
                </div>
              </div>
            </th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <template v-for="section in sections">
            <tr :key="section.key">
              <td>#</td>
              <td :colspan="Object.keys(roles).length+1"><h3>{{$t(section.name)}}</h3></td>
              <td v-if="section.key==='descriptions'">
                <div class="open-new-table">
                  <default-button
                      size="medium"
                      type="outline"
                      :left-icon="'/default-icons/edit.svg'"
                      @click-element="openCharacteristics"
                      width="auto"
                  />
                </div>
              </td>
              <td v-else>
                <div class="open-new-table" v-if="section.key!=='blocks'">
                  <icon-component
                      :icon="'/default-icons/lock.svg'"
                      :color="'var(--colorIconSecondary)'"
                      :size="20"
                  />
                </div>
              </td>
            </tr>
            <template v-if="section.children?.length">
              <tr
                  v-for="elem in section.children"
                  :key="elem.key"
              >
                <td>
                  <div class="settings-choice__checkbox" >
                    <input
                        class="custom-checkbox"
                        :id="'selectedAccess'+elem.key"
                        type="checkbox"
                        v-model="tempCheckParam[elem.key]"
                        @input="invertParam(elem.key)"
                    />
                    <label :for="'selectedAccess'+elem.key"></label>
                  </div>
                </td>
                <td class="param-name">{{$t(elem.name)}}</td>
                <td v-for="(name, role) in roles" :key="elem.key+role">
                  <div class="settings-choice__checkbox">
                    <input
                        class="custom-checkbox"
                        :id="'selectedAccess'+elem.key+role"
                        type="checkbox"
                        v-model="elem[role]"
                    />
                    <label :for="'selectedAccess'+elem.key+role"></label>
                  </div>
                </td>
                <td>
                  <div class="open-new-table" v-if="elem.key.includes('custom')">
                    <default-popup
                        width="fit-content"
                    >
                      <template v-slot:buttonContent>
                        <icon-component
                            :icon="'/default-icons/more_horiz.svg'"
                            :color="'var(--colorIconAccent)'"
                            :size="20"
                        />
                      </template>
                      <template v-slot:content>
                        <default-button
                            @click-element="editCustom(elem)"
                            type="tertiary"
                            size="medium"
                            leftIcon="/default-icons/info_fill.svg"
                        >{{$t('Подробнее')}}</default-button>
                        <default-button
                            @click-element="deleteCustom(elem)"
                            type="tertiary"
                            size="medium"
                            leftIcon="/default-icons/delete.svg"
                        >{{$t('Удалить')}}</default-button>
                      </template>
                    </default-popup>
                  </div>
                  <div class="open-new-table" v-else-if="section.key!=='blocks'">
                    <icon-component
                        :icon="'/default-icons/lock.svg'"
                        :color="'var(--colorIconSecondary)'"
                        :size="20"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
        <default-button  :size="'medium'" @click-element="saveParams">{{ $t('сохранитьИзменения') }}</default-button>
      </div>
    </template>
    <div class="editor-settings-object-loader" v-else>
      <loader :text="$t('ДанныеЗагружаются')"></loader>
    </div>
  </div>
</template>

<script>
import DefaultTabs from "@/components/redesign/defaultTabs.vue";
import draggable from 'vuedraggable'
import DefaultButton from "@/components/redesign/defaultButton.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";
import Vue from "vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import FormItem from "@/components/redesign/FormItem.vue";
import DefaultNewInput from "@/components/redesign/defaultNewInput.vue";
import SegmentedControl from "@/components/redesign/segmentedControl.vue";
import TextareaComponent from "@/components/redesign/textareaComponent.vue";
import SwitchComponent from "@/components/redesign/switchComponent.vue";
import DefaultNewDropdown from "@/components/redesign/defaultNewDropdown.vue";
import IconPicker from "@/components/redesign/IconPicker.vue";
import ColorPicker from "@/components/redesign/colorPickerComponent/colorPicker.vue";
import FillPropertyEditor
  from "@/components/redesign/graphicEditorComponents/graphicEditorPropertyEditors/fillPropertyEditor.vue";
import FillComponent from "@/components/redesign/FillComponent.vue";
import FillDropdown from "@/components/redesign/FillDropdown.vue";
import FromMediaUploader from "@/components/redesign/FromMediaUploader.vue";
import GroupHeader from "@/components/redesign/groupHeader.vue";
import MediaUploader from "@/components/redesign/MediaUploader.vue";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import DefaultPopup from "@/components/redesign/defaultPopup.vue";

export default {
  name: "ObjectSettingsEditorProject",
  components: {
    DefaultPopup,
    OptionsRow,
    MediaUploader,
    GroupHeader,
    FromMediaUploader,
    FillDropdown,
    FillComponent,
    FillPropertyEditor,
    ColorPicker,
    IconPicker,
    DefaultNewDropdown,
    SwitchComponent,
    TextareaComponent,
    SegmentedControl,
    DefaultNewInput, FormItem, ModalContainer, Loader, IconComponent, DefaultButton, DefaultTabs, draggable},
  data() {
    return{
      load:true,
      loadSettings:true,
      tabsTypesObject:[],
      currentTab: null,
      tempCheckRole: {},
      defaultStateRoles:{},
      tempCheckParam: {},
      sections:[],
      visibleSettingsGallery:false,
      visibleSaveBlock:false,
      visibleEditorTable:false,
      editingRows:[],
      addBlockTypes:[
        {
          value: this.$i18n.t('Баннер'),
          id: 'banner'
        },
        {
          value: this.$i18n.t('Галерея'),
          id: 'gallery'
        },
        {
          value: this.$i18n.t('iFrame'),
          id: 'iFrame'
        }
      ],
      currentBlockType:   {
        value: this.$i18n.t('Баннер'),
        id: 'banner'
      },
      backgroundBannerThemes:[
        {
          value: this.$i18n.t('Тёмный'),
          id: 'dark'
        },
        {
          value: this.$i18n.t('Светлый'),
          id: 'light'
        }
      ],
      currentBackgroundBannerTheme:{
        value: this.$i18n.t('Тёмный'),
        id: 'dark'
      },
      contentBannerTypes:[
        {
          icon:'/default-icons/block.svg',
          id: 'none',
          value:this.$i18n.t('Нет')
        },
        {
          icon:'/default-icons/account_circle.svg',
          id: 'avatar',
          value:this.$i18n.t('Профиль')
        },
        {
          icon:'/default-icons/panorama.svg',
          id: 'image',
          value:this.$i18n.t('Медиа')
        },
        {
          icon:'/default-icons/add_circle.svg',
          id: 'icon',
          value:this.$i18n.t('Иконка')
        }
      ],
      currentBannerContentType: {
        icon:'/default-icons/add_circle.svg',
        id: 'icon',
        value:this.$i18n.t('Иконка')
      },
      optionsBanner:{
        title:'',
        title_color:{
          type:'color',
          value:''
        },
        subtitle:'',
        subtitle_color:{
          type:'color',
          value:''
        },
        description_color:{
          type:'color',
          value:''
        },
        description:'',
        left_button:{
          text:'',
          link:''
        },
        right_button:{
          text:'',
          link:''
        },
        close:false,
        background:{
          type:'color',
          value:''
        },
        mediaImgFromBackground:false,
        mediaImgUpload:'',
        icon:'',
        icon_color:{
          type:'color',
          value:''
        }
      },
      editorBlock:{
        name:'',
        type:'',
        options:{

        }
      },
      temporaryGalleryList:[],
      fixGalleryList:[{value: this.$i18n.t('Раздел'), media:[]}],
      currentGallery:{value: this.$i18n.t('Раздел'), media:[]},
      iFrameLink:'',
      visibleErrorGeneral:false,
      visibleErrorGallery:false,
      currentSectionCustom:null,
    }
  },
  methods:{
    saveEditingRows(){
      this.sections.forEach((section,index)=>{
        if(section.key==='blocks'){
          this.sections[index].children=this.editingRows
        }
      })
      this.closeEditorTable()
    },
    closeEditorTable(){
      this.visibleEditorTable=false
      this.editingRows=[]
    },
    openEditorTable(){
      this.editingRows=this.sections.find(section=>section.key==='blocks').children
      this.visibleEditorTable=true
    },
    editGallery(){
      this.temporaryGalleryList=structuredClone(this.fixGalleryList)
      this.temporaryGalleryList=this.temporaryGalleryList.map((point,index)=>({...point, key:`point_${index+1}`}))
      this.visibleSettingsGallery=true
    },
    deleteGalleryPoint(block){
      this.temporaryGalleryList=this.temporaryGalleryList.filter(point=>point.key!==block.key)
      this.$root.addSnackbar({text:this.$i18n.t('УдаленаВкладкаГалереи'),  icon:'/default-icons/delete.svg', fill:'colorIconAccent'})
    },
    addGalleryPoint(){
      let keyIndex=0
      this.temporaryGalleryList.forEach(block=>{
        if(block.key.includes('point')){
          const currentKeyIndex=block.key.split('_')[1]
          if(currentKeyIndex&&Number(currentKeyIndex)>keyIndex)keyIndex=Number(currentKeyIndex)
        }
      })
      this.temporaryGalleryList.push({key:`point_${keyIndex+1}`,value:'',media:[]})
      this.$root.addSnackbar({text:this.$i18n.t('ДобавленаНоваяВкладкаГалереи'), icon:'/default-icons/check_circle.svg', fill:'colorIconPositive'})
    },
    saveSettingsGallery(){
      let errorEmpty = false
      let errorCopy = false
      const listValues=[]
      this.temporaryGalleryList.forEach(block=>{
        listValues.push(block.value)
        if(!block.value) errorEmpty=true
      })

      const uniqList= new Set(listValues)

      if(listValues.length!==uniqList.size){
        errorCopy=true
        alert(this.$i18n.t('СозданиеВкладокСОдинаковымиНазваниямиЗапрещено'))
      }

      if(!errorEmpty&&!errorCopy){
        this.visibleErrorGallery=false
        this.fixGalleryList=this.temporaryGalleryList.map(point=>({media:point.media, value:point.value}))
        this.currentGallery=this.fixGalleryList[0]
        this.$root.addSnackbar({text:this.$i18n.t('ГалереяИзменена'), icon:'/default-icons/check_circle.svg', fill:'colorIconPositive'})
        this.closeSettingsGallery()
      }else {
        this.visibleErrorGallery=true
      }
    },
    closeSettingsGallery(){
      this.visibleSettingsGallery=false
      this.visibleErrorGallery=false
      this.temporaryGalleryList=[]
    },

    deleteCustom(element){
      this.sections.forEach((section,index)=>{
        if(section.key==='blocks'){
          this.sections[index].children=section.children.filter(child=>child.key!==element.key)
        }
      })
    },
    editCustom(element){
      this.currentBlockType=this.addBlockTypes.find(type=>type.id===element.type)
      this.editorBlock.name=element.name
      if(element.type==='banner'){
        this.optionsBanner.title=element.options.title
        this.optionsBanner.title_color.value=element.options.title_color
        this.optionsBanner.subtitle=element.options.subtitle
        this.optionsBanner.subtitle_color.value=element.options.subtitle_color
        this.optionsBanner.description=element.options.description
        this.optionsBanner.description_color.value=element.options.description_color
        this.optionsBanner.left_button= element.options.left_button
        this.optionsBanner.right_button=element.options.right_button
        this.optionsBanner.close=element.options.close
        this.currentBackgroundBannerTheme=this.backgroundBannerThemes.find(type=>type.id===element.options.theme_background)

        if(element.options.background_img){
          this.optionsBanner.background= {
            type:'image',
            value:element.options.background_img
          }
        } else {
          this.optionsBanner.background= {
            type:'color',
            value:element.options.background_color
          }
        }
        this.currentBannerContentType=this.contentBannerTypes.find(type=>type.id===element.options.content)

        if(this.currentBannerContentType.id==='icon'){
          this.optionsBanner.icon=element.options.icon
          this.optionsBanner.icon_color.value=element.options.icon_color
        } else if(this.currentBannerContentType.id==='image'){
          if(element.options.background_img===element.options.img)this.optionsBanner.mediaImgFromBackground=true
          else this.optionsBanner.mediaImgUpload=element.options.img
        }
      }else if(element.type==='gallery'){
        this.fixGalleryList=element.options.list_tabs
        this.currentGallery=this.fixGalleryList[0]
      } else if(element.type==='iFrame'){
        this.iFrameLink=element.options.link
      }
      this.currentSectionCustom=element
      this.visibleSaveBlock = true
    },
    startEditBlock(){
     this.visibleSaveBlock=true
    },

    saveBlockConfirm(){
      if(this.editorBlock.name&&((this.currentBlockType.id==='banner'&&this.optionsBanner.title)||(this.currentBlockType.id==='iFrame'&&this.iFrameLink)||this.currentBlockType.id==='gallery')){
        this.visibleErrorGeneral=false
        const options = {}
        this.editorBlock.type=this.currentBlockType.id
        if(this.editorBlock.type==='banner'){
          options.title=this.optionsBanner.title
          options.title_color=this.optionsBanner.title_color.value
          options.subtitle=this.optionsBanner.subtitle
          options.subtitle_color=this.optionsBanner.subtitle_color.value
          options.description=this.optionsBanner.description
          options.description_color=this.optionsBanner.description_color.value
          options.left_button=this.optionsBanner.left_button
          options.right_button=this.optionsBanner.right_button
          options.close=this.optionsBanner.close
          options.theme_background=this.currentBackgroundBannerTheme.id
          if(this.optionsBanner.background.type==='image'){
            options.background_img=this.optionsBanner.background.value
          } else {
            options.background_color=this.optionsBanner.background.value
          }
          options.content=this.currentBannerContentType.id
          if(this.currentBannerContentType.id==='icon'){
            options.icon=this.optionsBanner.icon
            options.icon_color=this.optionsBanner.icon_color.value
          } else if(this.currentBannerContentType.id==='image'){
            if(this.optionsBanner.mediaImgFromBackground)options.img=options.background_img
            else options.img=this.optionsBanner.mediaImgUpload
          }

        } else if(this.editorBlock.type==='gallery'){
          options.list_tabs=structuredClone(this.fixGalleryList)
        }else if(this.editorBlock.type==='iFrame'){
          options.link=this.iFrameLink
        }
        this.editorBlock.options=options
        if(this.currentSectionCustom){
          this.sections.forEach((section,index)=>{
            if(section.key==='blocks'){
              section.children.forEach((child, indexChild)=>{
                if(child.key===this.currentSectionCustom.key){
                  this.sections[index].children[indexChild]={...this.currentSectionCustom, name:this.editorBlock.name, type:this.editorBlock.type, options:this.editorBlock.options}
                }
              })
            }
          })
        }else {
          let keyIndex=0
          this.sections.forEach((section,index)=>{
            if(section.key==='blocks'){
              section.children.forEach(child=>{
                if(child.key.includes('custom')){
                  const currentKeyIndex=child.key.split('_')[1]
                  if(currentKeyIndex&&Number(currentKeyIndex)>keyIndex)keyIndex=Number(currentKeyIndex)
                }
              })
              const resultBlock = {...this.editorBlock, key:`custom_${keyIndex+1}`, access:Object.keys(this.roles)}
              this.sections[index].children.push( {...resultBlock, ...this.setRoles(resultBlock)})
            }
          })
        }

        this.closeEditorBlock()
      } else {
        this.visibleErrorGeneral=true
      }
    },
    clearOptionsBanner(){
      this.currentBlockType= {
        value: this.$i18n.t('Баннер'),
        id: 'banner'
      }
      this.currentBackgroundBannerTheme={
        value: this.$i18n.t('Тёмный'),
        id: 'dark'
      }
      this.currentBannerContentType={
        icon:'/default-icons/add_circle.svg',
        id: 'icon',
        value:this.$i18n.t('Иконка')
      }
      this.optionsBanner={
        title:'',
        title_color:{
          type:'color',
          value:''
        },
        subtitle:'',
        subtitle_color:{
          type:'color',
          value:''
        },
        description_color:{
          type:'color',
          value:''
        },
        description:'',
        left_button:{
          text:'',
          link:''
        },
        right_button:{
          text:'',
          link:''
        },
        close:false,
        background:{
          type:'color',
          value:''
        },
        mediaImgFromBackground:false,
        mediaImgUpload:'',
        icon:'',
        icon_color:{
          type:'color',
          value:''
        }
      }
    },
    closeEditorBlock(){
      this.visibleSaveBlock=false

      this.currentSectionCustom=null

      this.editorBlock.type=''
      this.editorBlock.name=''
      this.editorBlock.options= {}

      this.fixGalleryList=[{value: this.$i18n.t('Раздел'), media:[]}]
      this.currentGallery={value: this.$i18n.t('Раздел'), media:[]}
      this.iFrameLink=''
      this.clearOptionsBanner()
      this.visibleErrorGeneral=false
    },
    setRoles(section) {
      const roles = {}
      for (const role in this.roles) {
        if(section.access?.length)roles[role] = !!(section.access.includes(role));
      }
      return roles
    },
    getAccess(section) {
      const newArr = []
      for (const role in this.roles) {
        const keySection = Object.keys(section)
        if(keySection.includes(role) && section[role] === true) {
          newArr.push(role)
        }
      }
      return newArr
    },
    sectionsSetRoles(sections) {
      for (let section of sections) {
          if(section.children) {
            section.children = section.children.map(el => ({...el, ...this.setRoles(el)}))
          }
      }
      return sections
    },
    filterSections(sections) {
      const newArr = []
      sections.forEach((section,index) => {
        const obj ={
          key: section.key,
          name: section.name,
          index: index
        }
        if(section.type)obj.type=section.type
        if(section.options)obj.options=section.options

        if(section.children?.length) {
          obj.children = this.filterSections(section.children)
        }
        const access = this.getAccess(section)
        if(access?.length)obj.access=access
        newArr.push(obj)
      });
      return newArr
    },
    saveParams(){
      try {
        const data = this.filterSections(structuredClone(this.sections))
        axios.post('sections/object/save', {
          sections: data
        }, {
          params: {
            typeObject: this.currentTab,
            id: this.$route.params.id
          }
        }).then(()=>{
          this.$emit('successfulSave')
        })
      } catch (error) {
        this.$emit('unsuccessfulSave')
      }
    },
    openCharacteristics(){
      this.$router.push(`/building-characteristics/${this.$route.params.id}/${this.currentTab}`)
    },
    invertRole(role){
      this.tempCheckRole[role] = !this.tempCheckRole[role]
      this.sections=this.sections.map(section=>{
        section.children=section.children.map(el=>{
          el[role]=this.tempCheckRole[role]
          return el
        })
        return section
      })
    },
    invertParam(param){
      this.tempCheckParam[param] = !this.tempCheckParam[param]
      this.sections=this.sections.map(section=>{
        section.children=section.children.map(el=>{
          if(el.key===param){
            Object.keys(this.roles).forEach(role=>{
              el[role]=this.tempCheckParam[param]
            })
          }
          return el
        })
        return section
      })
    },
    checkStateSections(tree){
      let stateRoles = structuredClone(this.defaultStateRoles)
      tree.forEach(node=>{
        if(node.children?.length){
          node.children.forEach(child=>{
            Object.keys(stateRoles).forEach(role=>{
              if(!child[role])stateRoles[role]=false
            })
            let access = this.getAccess(child)
            if(access.hasAll(Object.keys(this.roles))){
              Vue.set(this.tempCheckParam, child.key, true)
            } else {
              Vue.set(this.tempCheckParam, child.key, false)
            }
          })
        }
      })
      this.tempCheckRole=stateRoles
    },
    async initSettings(){
      this.loadSettings = true
      const settings = await axios.get('sections/object/get-all', {
        params: {
          id: this.$route.params.id,
          typeObject: this.currentTab,
        }
      })

      if(settings?.data?.length){
        this.sections = this.sectionsSetRoles(settings.data)
      }
      this.loadSettings = false
    }
  },
  watch:{
    currentTab(newValue){
      localStorage.setItem('TypeSettingsObject', JSON.stringify(newValue))
      this.initSettings()
    },
    sections:{
      handler(newValue){
        this.checkStateSections(newValue)
      }, deep:true
    },
    'optionsBanner.background'(){
      if(this.disabledCopyImgBackground)this.optionsBanner.mediaImgFromBackground=false
    },
    'optionsBanner.mediaImgFromBackground'(newValue){
      if(newValue)this.optionsBanner.mediaImgUpload=''
    },
  },
  computed:{
    dragOptions() {
      return {
        animation: 0,
        group: "descriptions",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    disabledCopyImgBackground(){
      return this.optionsBanner.background.type!=='image'||!this.optionsBanner.background.value
    },
    roles(){
      return this.$store.getters.getAllRoles
    },
  },
  async mounted() {
    this.load = true
    this.tabsTypesObject = await axios.get('/complex/get-types-objects?id=' + this.$route.params.id)
        .then(e => e?.data ?? [])
        .catch(() => [])
    let storageType = localStorage.getItem('TypeSettingsObject')&&localStorage.getItem('TypeSettingsObject')!=='undefined'?JSON.parse(localStorage.getItem('TypeSettingsObject')) : null

    this.currentTab = storageType && this.tabsTypesObject.some(el => el === storageType) ?
        storageType :
        this.tabsTypesObject[0]
    for (const role in this.roles) {
      this.defaultStateRoles[role]=true
    }
    this.load = false
  },

}
</script>

<style scoped lang="scss">
.editor-rows-table{
  display: flex;
  flex-direction: column;
  .flip-list-move {
    transition: transform 0.5s;
  }
  .editor-rows-section{
    display: flex;
    align-items: center;
    gap: 12px;
    .editor-rows-section__drag{
      cursor: pointer;
    }
    .editor-rows-section__name{
      padding: 10px 0;
      width: 100%;

      color: var(--colorTextPrimary);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
      letter-spacing: 0.024px;
    }
  }
}
.edit-settings-gallery{
  display: flex;
  flex-direction: column;
  .flip-list-move {
    transition: transform 0.5s;
  }
  .gallery-section{
    display: flex;
    align-items: flex-end;
    .gallery-section__drag{
      padding: 36px 12px 12px 0;
      cursor: pointer;
    }
    .gallery-section__form{
      width: 100%;
    }
  }
  @media screen and (max-width: 1365px){
    .gallery-section{
      .gallery-section__drag{
        padding: 42px 12px 18px 0;
      }
    }
  }
}
.functions-settings-gallery{
  padding: 0 4px;
}
.block-add-modal{
  .default-section{
    display: flex;
    gap: 16px;
    .default-section__block{
      width: calc(50% - 8px);
    }
  }
  .section-with-padding{
    padding: 0 16px;
  }
  .separator-editor-banner{
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .separator-editor-banner__line{
      height: 1px;
      width: 100%;
      background: var(--colorStrokeSecondary);
    }
  }
  .banner-editor{
    .banner-editor__header{
      padding: 12px 16px 4px;
      .title-block-banner{
        padding: 6px 0;

        color: var(--colorTextPrimary);

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
      }
    }
    .banner-editor__form{
      .info-banner{
        padding: 0 16px;
      }
      .cover-banner{
        .tabs-media-desktop{
          display: block;
        }
        .tabs-media-mobile{
          display: none;
        }
      }
      .description-cover-banner{
        padding: 8px 0;
        color: var(--colorTextPrimary);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 0.016px;
      }
      .media-settings{
        padding: 6px 16px;
      }
    }
    .editor-gallery-desktop{
      padding: 2px 0;
    }
    .editor-gallery-mobile{
      display: none;
    }
  }
}
.header-functions-table{
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  .header-functions-table__right{
    display: flex;
    align-items: center;
    gap:8px;
  }
}
.object-settings-page{
  .object-settings-types{
    padding: 8px 0;
  }
  .table-settings{
    .open-new-table{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .param-name{
      cursor: pointer;
    }
    .settings-choice-wrapper{
      display: flex;
      align-items: center;
      gap: 4px;
      .settings-choice__text{
        cursor: pointer;
        color: var(--colorTextPrimary);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.07px;
        .settings-choice__text_label{
          cursor: pointer;
        }
      }
    }
    .settings-choice__checkbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      /* для элемента input c type="checkbox" */
      .custom-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      /* для элемента label, связанного с .custom-checkbox */
      .custom-checkbox + label {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* создание в label псевдоэлемента before со следующими стилями */
      .custom-checkbox + label::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        background-repeat: no-repeat;
        background-position: center center;


        background-size: contain;
        background-color: var(--colorComponentCheckboxIcon);
        -webkit-mask: url('/public/my/checkboxDefault.svg') no-repeat center / contain;
        mask: url('/public/my/checkboxDefault.svg') no-repeat center / contain;

      }

      /* стили при наведении курсора на checkbox */
      .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {

      }

      /* стили для активного чекбокса (при нажатии на него) */
      .custom-checkbox:not(:disabled):active + label::before {

      }

      /* стили для чекбокса, находящегося в фокусе */
      .custom-checkbox:focus + label::before {

      }

      /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
      .custom-checkbox:focus:not(:checked) + label::before {

      }

      /* стили для чекбокса, находящегося в состоянии checked */
      .custom-checkbox:checked + label::before {
        background-color: var(--colorComponentCheckboxIconActive);
        -webkit-mask: url('/public/my/checkboxActive.svg') no-repeat center / contain;
        mask: url('/public/my/checkboxActive.svg') no-repeat center / contain;
      }

    }
  }
}
.editor-settings-object-loader{
  padding: 20px 0;
}

@media screen and (max-width: 743px){
  .block-add-modal{
    .default-section{
      display: flex;
      flex-direction: column;
      gap: 0;
      .default-section__block{
        width: 100%;
      }
    }
    .banner-editor{
      .banner-editor__form{
        .cover-banner{
          .tabs-media-desktop{
            display: none;
          }
          .tabs-media-mobile{
            display: block;
          }
        }
        .media-settings{
          padding: 8px 16px;
        }
      }
      .editor-gallery-desktop{
        display: none;
      }
      .editor-gallery-mobile{
        display: block;
        padding: 2px 0;
      }
    }
  }
}
</style>