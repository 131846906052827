<template>
    <div class="snackbar-wrapper">
      <div class="snackbar-container">
        <div class="snackbar-icon" v-if="icon">
          <div class="snackbar-icon__body" :style="`mask: url('${icon}') no-repeat center / contain;  -webkit-mask: url('${icon}') no-repeat center / contain; background-color: var(--${fill});`">
          </div>
        </div>
        <div class="snackbar-text">
          <div class="snackbar-text__title"><slot></slot></div>
          <div class="snackbar-text__subtitle" v-if="subtitle">{{subtitle}}</div>
        </div>
        <div class="snackbar-btn" v-if="actionText">
          <div class="snackbar-btn__body" @click="clickAction">
            {{actionText}}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "snackbarItem",
  props:{
    subtitle:{
      type:String,
      default:''
    },
    fill:{
      type:String,
      default:'colorIconPositive'
    },
    icon:{
      type:String,
      default:'/img/service_icons/check_circle.svg'
    },
    actionText:{
      type:String,
      default:''
    }
  },
  methods:{
    clickAction(){
      this.$emit('click-action')
    }
  }
}
</script>

<style lang="scss" scoped>
.snackbar-wrapper{
  width: 100%;
  padding: 8px;
  .snackbar-container{
    border-radius: 8px;
    background: var(--colorComponentDropdownListItemBackground);

    /* Light/Elevation M */
    box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05), 0px 0px 8px 0px rgba(30, 30, 36, 0.10);

    display: flex;
    align-items: center;
    align-self: stretch;
    .snackbar-icon{
      padding: 14px 0 14px 12px;
      .snackbar-icon__body{
        width: 28px;
        height: 28px;
      }
    }
    .snackbar-text{
      display: flex;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      flex: 1 0 0;
      .snackbar-text__title{
        color: var(--colorTextPrimary);

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 0.016px;
      }
      .snackbar-text__subtitle{
        color: var(--colorTextSecondary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.035px;
      }
    }
    .snackbar-btn{
      padding: 10px 0;
      .snackbar-btn__body{
        cursor: pointer;
        padding: 9px 16px;

        color: var(--colorTextAccent);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.07px;
      }
    }
  }
}
</style>