<template>
  <div class="indicator-counter" :class="[type, appearance, size]">
    <div class="indicator-counter__container">{{counter}}</div>
  </div>
</template>

<script>
/**
 * @prop {Number} counter - показатель счётчика
 *
 * @prop {String} type - Отвечает за тип счётчика и имеет следующие состояния:
 * 1.contrast (дефолтное состояние)
 * 2.primary
 * 3.secondary (только при appearance = neutral)
 *
 * @prop {String} appearance - Отвечает за внешний вид счётчика и имеет следующие состояния:
 * 1.default-appearance (дефолтное состояние)
 * 2.negative
 * 3.neutral
 *
 * @prop {String} size - Отвечает за размер счётчика и имеет следующие состояния:
 * 1.medium (дефолтное состояние)
 * 2.small
 */
export default {
  name: "IndicatorCounter",
  props:{
    counter:{
      type:Number,
      default:0
    },
    type: {
      type: String,
      default: 'contrast'
    },
    appearance: {
      type: String,
      default: 'default-appearance'
    },
    size:{
      type: String,
      default: 'medium'
    },
  },

}
</script>

<style scoped lang="scss">
  .indicator-counter{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 12px;

    text-align: center;
    font-style: normal;

    &.contrast{
      background: var(--colorIconAccent);
      color: var(--colorTextContrast);
    }
    &.primary{
      background: var(--colorIconContrastInvariably);
      color: var(--colorTextAccentInvariably);
    }

    &.negative{
      &.contrast{
        background: var(--colorIconNegative);
        color: var(--colorTextContrastInvariably);
      }
      &.primary{
        background: var(--colorIconContrastInvariably);
        color: var(--colorTextNegative);
      }
    }

    &.neutral{
      &.contrast{
        background: var(--colorIconPrimary);
        color: var(--colorTextContrast);
      }
      &.primary{
        background: var(--colorIconContrast);
        color: var(--colorTextPrimary);
      }
      &.secondary{
        background: var(--colorBackgroundSecondary);
        color: var(--colorTextPrimary);
      }
    }

    &.medium{
      height: 20px;
      padding: 0 2px;

      font-size: 12px;
      font-weight: 500;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.03px;
      .indicator-counter__container{
        min-width: 16px;
      }

    }
    &.small{
      padding: 2px;

      font-size: 8px;
      font-weight: 400;
      line-height: 12px; /* 150% */
      letter-spacing: 0.008px;

      .indicator-counter__container{
        min-width: 12px;
      }
    }
  }
</style>