<template>
  <div class="profile-block-card-wrapper">
    <div class="profile-block-card">
      <div class="profile-block-card__container" :class="{'profile-card-without-padding':withoutPadding}">
        <div class="image-card" v-if="img">
          <img :src="img"/>
        </div>
        <div class="information-card">
          <div class="information-card__description">
            <div class="text-card-info">
              <div class="selected-title" v-if="title">{{title}}</div>
              <div class="selected-description" v-for="row in info" v-if="row" :key="row">{{row}}</div>
            </div>
            <slot></slot>
          </div>
          <div class="information-card__functions" v-if="primaryBtn||negativeBtn">
            <div class="information-card__functions_el" v-if="primaryBtn">
              <default-button
                  size="small"
                  type="secondary"
                  @click-element="usePrimary"
              >{{primaryBtn}}</default-button>
            </div>
            <div class="information-card__functions_el" v-if="negativeBtn">
              <default-button
                  size="small"
                  type="secondary"
                  appearance="negative"
                  @click-element="useNegative"
              >{{negativeBtn}}</default-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";
export default {
  name: "profileCardComponent",
  components: {
    DefaultButton
  },
  inject:{
    withoutPadding:{
      default: false
    }
  },
  props:{
    title:{
      type:String,
      default:''
    },
    info:{
      type:Array,
      default(){
        return []
      }
    },
    img:{
      type:String,
      default:''
    },
    dotsOptions:{
      type:Boolean,
      default:false
    },
    primaryBtn:{
      type:String,
      default:''
    },
    negativeBtn:{
      type:String,
      default:''
    },
  },
  data(){
    return{

    }
  },
  methods:{
    usePrimary(){
      this.$emit('use-primary')
    },
    useNegative(){
      this.$emit('use-negative')
    },
  }
}
</script>

<style lang="scss" scoped>
.profile-block-card-wrapper{
  width: 100%;
  .profile-block-card{
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--colorBackgroundContent);
    border-radius: 12px;
    .profile-block-card__container{
      display: flex;
      gap: 12px;
      padding: 6px 16px;
      height: 100%;
      &.profile-card-without-padding{
        padding: 0;
      }
      .image-card{
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        img{
          width: 100%;
          height: 40px;
          border-radius: 100px;
          object-fit: cover;
        }
      }
      .information-card{
        gap: 4px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .information-card__description{
          //padding: 4px 0;
          display: flex;
          .text-card-info{
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            .selected-title{
              color: var(--colorTextPrimary);

              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 125% */
              letter-spacing: 0.024px;
            }
            .selected-description{
              white-space: pre-line;
              color: var(--colorTextSecondary);

              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
              letter-spacing: 0.012px;
            }
          }
        }
        .information-card__functions{
          padding: 4px 0;
          display: flex;
          gap: 8px;
          .information-card__functions_el{
            .btn-function-card{
              border: 0;
              outline: none;
              padding: 7px 14px;

              color: var(--colorTextAccent);
              text-align: center;

              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px; /* 116.667% */
              letter-spacing: 0.03px;

              border-radius: 8px;
              background: var(--colorBackgroundSecondary);
              &.negative-btn{
                color: var(--colorTextNegative);
              }
            }
          }
        }
      }
    }
  }
}
</style>