/**
 * Класс Position представляет собой позицию с координатами x и y.
 */
export class Position {
    /**
     * Создает экземпляр Position.
     *
     * @param {number} x - Координата x.
     * @param {number} y - Координата y.
     */
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}