<script setup>
import {getCurrentInstance, ref} from "vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import AlertModal from "@/components/redesign/alertModal.vue";
import i18n from '@/i18n';

/**
 * @prop {Array} value - Компонент принимает и отдаёт массив ссылок на изображения
 */

const props = defineProps({
  value:{
    type: Array,
    default(){
      return []
    }
  }
})
const emits = defineEmits(['input'])

const changingState = ref(false)
const selectedMedia = ref([])

const cancelChangingState = ()=>{
  changingState.value = false
  selectedMedia.value=[]
}

const changeSelectedMedia = (image)=>{
  if(selectedMedia.value.includes(image)){
    selectedMedia.value=selectedMedia.value.filter(el=>el!==image)
  } else {
    selectedMedia.value.push(image)
  }
}

const visibleAlert=ref(false)

const startDeleteSelected = ()=>{
  visibleAlert.value=true
}

const root = getCurrentInstance();

const endDeleteSelected = ()=>{
  changingState.value = false
  visibleAlert.value=false
  emits('input', props.value.filter(el=>!selectedMedia.value.includes(el)))
  root.proxy.$root.addSnackbar({text:`${i18n.t('ИзображенийУдалено')}: ${selectedMedia.value.length}`, icon:'/default-icons/delete.svg', fill:'colorIconAccent'})
  selectedMedia.value=[]
}

const isLoadingFiles=ref(false);
const file = ref(null)

const addMedia = ()=>{
  const promises = []
  for (const fileSingle of file.value.files) {
    const form = new FormData()
    isLoadingFiles.value=true
    form.append('file', fileSingle)
    const promise = axios.post('https://cdn.itrielt.ru/upload/file', form).then((response)=>{
      if (response.data.status === 'success') {
        emits('input', [...props.value, response.data.url])
      }
    })
    promises.push(promise)
    form.delete('file');
  }
  Promise.all(promises).then(() =>{
    isLoadingFiles.value=false
    root.proxy.$root.addSnackbar({text:`${i18n.t('ИзображенийДобавлено')}: ${promises.length}`, icon:'/default-icons/check_circle.svg', fill:'colorIconPositive'})
  });
  file.value.value = ''
}

const openSelectFile=()=>{
  file.value.click()
}
</script>

<template>
  <div class="media-uploader-wrapper">
    <alert-modal
        v-if="visibleAlert"
        :title="selectedMedia.length===1?$t('УдалениеИзображения'):$t('УдалениеИзображений')"
        :description="`${$t('ВыУвереныЧтоХотитеУдалить')} ${selectedMedia.length===1?$t('этоИзображение'):$t('этиИзображения')} ?`"
        :confirmText="$t('Удалить')"

        @confirm-alert="endDeleteSelected"
        @cancel-alert="visibleAlert = false"
    />
    <div class="media-uploader-header">
      <template v-if="!changingState">
        <div class="media-uploader-header__btn" :key="'media-add'">
          <default-button
              :size="'medium'"
              :left-icon="'/default-icons/add_circle.svg'"
              @click-element="openSelectFile"
          >{{$t('Добавить')}}</default-button>
        </div>
        <div class="media-uploader-header__btn" :key="'media-select'">
          <default-button
              :size="'medium'"
              :type="'secondary'"
              :left-icon="'/default-icons/multi-select.svg'"
              :disabled="!value?.length"
              @click-element="changingState=true"
          >{{$t('Выбрать')}}</default-button>
        </div>
      </template>
      <template v-else>
        <div class="media-uploader-header__btn" key="'media-delete'">
          <default-button
              :size="'medium'"
              :type="'secondary'"
              :appearance="'negative'"
              :disabled="!selectedMedia?.length"
              @click-element="startDeleteSelected"
          >{{$t('Удалить')}}</default-button>
        </div>
        <div class="media-uploader-header__btn" key="'media-cancel'">
          <default-button
              :size="'medium'"
              :type="'secondary'"
              @click-element="cancelChangingState"
          >{{$t('Отменить')}}</default-button>
        </div>
      </template>
    </div>
    <div class="media-uploader-body" :class="{'media-uploader-changing':changingState}">
      <template v-if="value?.length">
        <div class="media-picture" v-for="image in value" :key="image" @click="changeSelectedMedia(image)">
          <div class="overlay-picture" :class="{'picture-selected':selectedMedia.includes(image)}" v-if="changingState">
            <div class="overlay-container">
              <div class="overlay-container__icon"></div>
            </div>
          </div>
          <img class="media-picture__el" :src="image" alt="picture"/>
        </div>
      </template>
      <div class="media-picture" v-if="isLoadingFiles">
        <loader />
      </div>
    </div>
    <input @change="addMedia" hidden type="file" name="file" ref="file" multiple>
  </div>
</template>

<style scoped lang="scss">
.media-uploader-wrapper{
  .media-uploader-header{
    padding: 8px 0;
    display: flex;
    gap: 8px;
  }
  .media-uploader-body{
    display: grid;
    grid-template-columns:repeat(6, 1fr);
    grid-gap: 4px;

    .media-picture{
      aspect-ratio: 1/1;
      display: flex;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      .overlay-picture{
        position: absolute;
        left: 0;
        top:0;
        right: 0;
        bottom: 0;
        background: var(--colorComponentAvatarOverlay);
        opacity: 0;
        transition: all 0.3s ease;
        cursor: pointer;
        .overlay-container{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          .overlay-container__icon{
            width: 32px;
            height: 32px;
            mask: url('/public/default-icons/done.svg') no-repeat center / contain;
            -webkit-mask: url('/public/default-icons/done.svg') no-repeat center / contain;
            background-color: var(--colorIconContrastInvariably);
          }
        }
      }
      .media-picture__el{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.media-uploader-changing{
      .media-picture{
    /*    &:hover{
          .overlay-picture{
            opacity: 1;
          }
        }*/
        .overlay-picture{
          &.picture-selected{
            opacity: 1;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 743px){
  .media-uploader-wrapper{
    .media-uploader-header{
      .media-uploader-header__btn{
        width: 100%;
      }
    }
    .media-uploader-body{
      grid-template-columns:repeat(3, 1fr);
      grid-gap: 2px;
    }
  }
}
</style>