<template>
  <div class="horizontal-scroll-tabs" :class="{'padding-tabs':padding, 'container-view-tabs':containerView}">
    <div class="tab-btn left-btn" v-if="visibleLeftBtn">
      <default-button :shadow="true" :size="size" :left-icon="'/default-icons/navigate_before.svg'" :type="'tertiary'" @click-element="moveNavigation('previous')"/>
    </div>
    <div class="tab-btn right-btn" v-if="visibleRightBtn">
      <default-button :shadow="true" :size="size" :left-icon="'/default-icons/navigate_next.svg'" :type="'tertiary'" @click-element="moveNavigation('next')"/>
    </div>
    <div :class="`position-tabs-${position}`">
      <div
          ref="tabScroll"
          class="tabs-container"
          :class="[{'vertical': setVertical},{'stretched':stretched}, mode, size]"
          v-show="tabs.length"
          @mouseenter="hoverTabs"
      >
        <template v-if="tabs[0] !== null && typeof tabs[0] === 'object'">
          <div class="tab" :ref="`tab-${index}`" :class="{'active': (tab.value === value.value && activeVisible), 'stretched':stretched}" v-for="(tab,index) in tabs" :key="index+tab.value" @click="onTabSelected(tab, index, tab.value === value.value)">
            <div class="tab-content-wrapper">
              <div class="tab-content">
                <div class="tab-icon" v-if="tab.icon&&iconVisible" :style="`mask: url(${tab.icon}) no-repeat center / contain;`"></div>
                <div class="tab-value-wrapper" v-if="tab.value&&labelVisible">
                  <span class="tab-value">{{ $t(tab.value) }}</span>
                </div>
              </div>
            </div>
            <div v-if="mode === 'primary'" class="line"></div>
          </div>
        </template>
        <template v-else>
          <div class="tab" :ref="`tab-${index}`" :class="{'active': (tab === value && activeVisible)}" v-for="(tab,index) in tabs" :key="index+tab" @click="onTabSelected(tab, index, tab === value)">
            <div class="tab-content-wrapper" v-if="tab&&labelVisible">
              <div class="tab-content">
                <div class="tab-value-wrapper">
                  <span class="tab-value">{{ $t(tab) }}</span>
                </div>
              </div>
            </div>
            <div v-if="mode === 'primary'" class="line"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";

/**
 * @prop {Array} tabs - Массив двух видов: 1. Массив строк с именами табов 2. Массив объектов, где каждый объект содержит поле icon - ссылка на иконку, поле value - значение таба {icon:'Ссылка',value:'Значение'}
 * @prop {String} value - Текущий таб
 * @prop {String} mode - Тема табов. Доступны три варианта: accent, primary, secondary
 * @prop {String} size - Размер табов. Доступны варианты: small, medium, large. Если проп отсутствует, то размер от 744 и выше будет large, а 0-743px будет medium
 * @prop {Boolean} iconVisible - Отвечает за отображение иконок
 * @prop {Boolean} labelVisible - Отвечает за отображение надписей
 * @prop {Boolean} stretched - Включает/выключает растягивание табов на всю ширину
 * @prop {Boolean} activeVisible - Включает/выключает отображение активного таба
 * @prop {Boolean} padding - Включает/выключает отступы у табов. По дефолту отступы отключены
 * @prop {String} position - Табы растягиваю на всю доступную ширину, и данный пропс позволяет изменить положения табов внутри контейнера. Состояния: left (дефолт), center, right
 * @prop {Boolean} containerView - Ширина табов изменяется в зависимости от размеров контейнера, а не страницы.
*/
export default {
  name: "defaultTabs",
  components: {DefaultButton},
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    value: {},
    mode: {
      type: String,
      default: 'accent'
    },
    size: {
      type: String,
      default: ''
    },
    setVertical: {
      type: Boolean,
      default: false
    },
    iconVisible: {
      type: Boolean,
      default: true
    },
    labelVisible: {
      type: Boolean,
      default: true
    },
    stretched: {
      type: Boolean,
      default: false
    },
    activeVisible: {
      type: Boolean,
      default: true
    },
    padding: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'left'
    },
    containerView: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      visibleLeftBtn:false,
      visibleRightBtn:false,
      finallyScrollLeft:0
    }
  },
  methods: {
    moveNavigation(direction) {
      const navigation= this.$refs.tabScroll

      if(direction==='previous'){
        this.finallyScrollLeft= navigation.scrollLeft - navigation.clientWidth;
      } else {
        this.finallyScrollLeft= navigation.scrollLeft + navigation.clientWidth;
      }

      navigation.scrollLeft = this.finallyScrollLeft
    },
    hoverTabs(){
      if(!this.$root.touchDevice){
        const navigation= this.$refs.tabScroll
        if(navigation.scrollWidth > navigation.clientWidth){
          this.watchScrollTab()
        } else {
          this.visibleRightBtn=false
          this.visibleLeftBtn=false
        }
      }
    },
    onTabSelected(value, index, statusActive) {
      if(!statusActive){
        const tab= this.$refs[`tab-${index}`]
        tab[0].scrollIntoView({ behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        })
      }
      this.$emit('input', value)
    },
    watchScrollTab(){
      const navigation= this.$refs.tabScroll
      this.finallyScrollLeft = navigation.scrollLeft
      this.visibleLeftBtn =  this.finallyScrollLeft > 0;
      this.visibleRightBtn =  this.finallyScrollLeft + navigation.clientWidth< navigation.scrollWidth;
    }
  },
  mounted(){
    if(!this.$root.touchDevice){
      const navigation= this.$refs.tabScroll
      if(navigation.scrollWidth > navigation.clientWidth){
        navigation.addEventListener('scroll', this.watchScrollTab);
      }
    }
  },
  beforeDestroy() {
    if(!this.$root.touchDevice){
      const navigation= this.$refs.tabScroll
      if(navigation.scrollWidth > navigation.clientWidth){
        navigation.removeEventListener('scroll', this.watchScrollTab);
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .horizontal-scroll-tabs{
    position: relative;
    .tab-btn{
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 1;

      transition: opacity .15s;
      &.left-btn{
        left: 0;
      }
      &.right-btn{
        right: 0;
      }
    }

    .tabs-container {
      display: flex;
      flex-wrap: nowrap;
      gap: 4px;
      flex-direction: row;
      white-space: nowrap;
      overflow: auto;

      scroll-behavior: smooth;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar{
        display: none;
      }
      &.vertical {
        flex-direction: column;
        width: 100%;
      }

      &.accent {
        .tab {
          background: var(--colorBackgroundSecondary);
          color: var(--colorTextSecondary);
          &.active{
            background: var(--colorBackgroundAccent);
            color: var(--colorTextContrast);
            &:hover{
              background: var(--colorBackgroundAccentHover);
            }
            .tab-content-wrapper{
              .tab-content{
                .tab-icon{
                  background-color: var(--colorIconContrast);
                }
              }
            }
          }
          &:hover{
            background: var(--colorBackgroundSecondaryHover);
          }
          .tab-content-wrapper{
            padding: 6px 0;
            .tab-content{
              .tab-value{
                padding: 1px 4px;
              }
            }
          }
        }
        &.small{
          .tab {
            .tab-content-wrapper{
              padding: 4px 0;
              .tab-content{

              }
            }
          }
        }
      }

      &.secondary {
        .tab {
          background: transparent;
          color: var(--colorTextSecondary);
          &.active{
            background: var(--colorBackgroundTertiaryActive);
            color: var(--colorTextAccent);
            .tab-content-wrapper{
              .tab-content{
                .tab-icon{
                  background-color: var(--colorIconAccent);
                }
              }
            }
          }
          &:hover{
            background: var(--colorBackgroundTertiaryHover);
          }
          .tab-content-wrapper{
            padding: 6px 0;
            .tab-content{
              .tab-value{
                padding: 1px 4px;
              }
            }
          }
        }
        &.small{
          .tab {
            .tab-content-wrapper{
              padding: 4px 0;
              .tab-content{

              }
            }
          }
        }
      }

      &.primary {
        .tab {
          background: transparent;
          color: var(--colorTextSecondary);
          &.active{
            background: transparent;
            color: var(--colorTextPrimary);

            .line {
              background-color: var(--colorIconAccent);
            }
            .tab-content-wrapper{
              .tab-content{
                .tab-icon{
                  background-color: var(--colorIconPrimary);
                }
              }
            }
          }
          &:hover{
            background: var(--colorBackgroundSecondaryHover);
          }
          .tab-content-wrapper{
            padding-top: 6px;
            .tab-content{
              .tab-value{
                padding: 0 4px 2px;
              }
            }
          }
        }
      }

      &.large {
        .tab {
          padding: 4px 12px;
          .tab-content-wrapper{
            .tab-content{
              gap: 4px;
              height: 24px;
              .tab-icon{
                width: 24px;
                height: 24px;
              }
            }
          }
          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.024px;
          }
        }
      }

      &.medium {
        .tab {
          padding: 2px 8px;
          .tab-content-wrapper{
            .tab-content{
              gap: 2px;
              height: 20px;
              .tab-icon{
                width: 20px;
                height: 20px;
              }
            }
          }
          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
          }
        }
      }

      &.small {
        .tab {
          padding: 0 8px;
          .tab-content-wrapper{
            .tab-content{
              height: 20px;
              .tab-icon{
                width: 16px;
                height: 16px;
              }
            }
          }
          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.03px;
          }
        }
      }

      .tab {
        padding: 4px 12px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease, color 0.1s ease;
        width: max-content;
        display: flex;
        flex-direction: column;
        .tab-content-wrapper{
          .tab-content{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            height: 24px;
            .tab-icon{
              background-color: var(--colorIconSecondary);
              width: 24px;
              height: 24px;
            }
          }
        }
        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.024px;
        }
        .line {
          margin-top: 4px;
          width: 100%;
          height: 2px;
          border-radius: 2px;
          background-color: transparent;
          transition: background-color 0.2s ease;
        }
      }
      &.stretched{
        width: 100%;
        .tab {
          flex: 1 0 0;
        }
      }
    }

    &:hover{
      .tab-btn{
        opacity: 1;
      }
    }
    &.container-view-tabs{
      container-type: inline-size;
    }
    &.padding-tabs{
      .tab-btn{
        &.left-btn{
          padding-left: 16px;
        }
        &.right-btn{
          padding-right: 16px;
        }
      }

      .tabs-container{
        padding: 0 16px;
      }
    }
    .position-tabs-center{
      display: flex;
      justify-content: center;
    }
    .position-tabs-right{
      display: flex;
      justify-content: flex-end;
    }
  }

@mixin mobileView(){
  .tabs-container{
    .tab {
      padding: 2px 8px;
      .tab-content-wrapper{
        .tab-content{
          gap: 2px;
          height: 20px;
          .tab-icon{
            width: 20px;
            height: 20px;
          }
        }
      }
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
      }
    }
  }
  &.padding-tabs{
    .tab-btn{
      &.left-btn{
        padding-left: 8px;
      }
      &.right-btn{
        padding-right: 8px;
      }
    }

    .tabs-container{
      padding: 0 8px;
    }
  }
}

@media screen and (max-width: 743px){
  .horizontal-scroll-tabs {
    @include mobileView;
  }
}

@container  (max-width: 743px) {
  .horizontal-scroll-tabs {
    &.container-view-tabs{
      @include mobileView;
    }
  }
}
</style>