/**
 * Класс Layer представляет собой слой с различными свойствами, такими как тип, путь и цвет.
 */
export class Layer {
    /**
     * Создает экземпляр Layer.
     *
     * @param {Object} params - Параметры для создания слоя.
     * @param {number|null} params.id - Идентификатор слоя (по умолчанию null, если не указан, будет сгенерирован случайный идентификатор).
     * @param {string|null} params.type - Тип слоя (по умолчанию null).
     * @param {Position[]}  params.path - Набор координат для полигона.
     * @param {string|null} params.name - Имя слоя (по умолчанию null).
     * @param {string|null} params.object_class - Класс объекта, к которому относится слой (по умолчанию null).
     * @param {Array|null} params.object_ids - Идентификатор объекта, к которому относится слой (по умолчанию null).
     * @param {string|null} params.source - Изображение слоя (по умолчанию null).
     * @param {string|null} params.color - Цвет слоя (по умолчанию undefined).
     */
    constructor({id = null, type = null, path = null, name = null, object_class = null, object_ids = [], source = null, color = null}) {
        this.id = id ?? Math.random();
        this.type = type;
        this.path = path ?? [];
        this.name = name;
        this.object_class = object_class;
        this.object_ids = object_ids;
        this.source = source;
        this.color = color ?? '#6F77FE7D';
    }
}