<script>

import Loader from "@/components/Loader.vue";

export default {
  name: "infiniteLoader",
  components: {Loader},
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    pageCount: {
      type: Number,
      default: null
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      infiniteLoader: null,
      timer: null
    }
  },
  computed: {
    hasMore() {
      if (this.pageCount == null) {
        return false
      }
      return this.page < this.pageCount
    }
  },
  methods: {
    getData() {
      this.$emit('change-page', this.page + 1)
    }
  },
  beforeDestroy() {
    if (this.infiniteLoader) {
      this.infiniteLoader.disconnect();
    }
  },
  mounted() {
    this.infiniteLoader = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !this.isLoading && this.hasMore) {
          if (this.timer) {
            clearTimeout(this.timer)
          }
          this.timer = setTimeout(() => {
            this.getData();
          }, 100)
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: "0.1",
      }
    );
    if (this.$refs.loader) {
      this.infiniteLoader.observe(this.$refs.loader);
    }
  },
  watch: {}
}
</script>

<template>
  <div ref="loader" class="loader">
    <div v-if="!hasMore && !isLoading">
      <slot class="no-more" name="no-more"></slot>
    </div>
    <div v-else-if="isLoading">
      <loader text="Загрузка"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>